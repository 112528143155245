import React from "react";
import { DetailsProps } from "./types";
import { Detail } from "./components/Detail";
import { detailPresets } from "./js/detailPresets";
import cx from "components/src/utils/cx";
import kebabCase from "lodash/kebabCase";

export const Details = ({ list, gap = "2xs", noPad = false, noWrap = false, className = ""}: DetailsProps) => {
  let firstIsLive: boolean;

  const _list = list?.filter(tag => !!tag).map((tag, i) => {
    const isFirst = i === 0 || list.length === 1;
    const isLast = i === list.length - 1 || list.length === 1;
    const _key = `cui__details-detail-${kebabCase(typeof tag === "string" ? tag : "")}-${i}`;
    const type = tag && "type" in tag ? tag.type : "";
    const _tag = tag && "tag" in tag ? tag.tag : "";
    const preset = tag && type && type in detailPresets ? detailPresets[type](tag) : {};
    const _showDivider =  isFirst || (i === 1 && firstIsLive) ? false : !Array.isArray(preset) && typeof preset?.showDivider === "boolean" ? preset?.showDivider : typeof tag?.showDivider === "boolean" ? tag?.showDivider : true;
    
    if (!firstIsLive) {
      firstIsLive = i === 0 && type === "live";
    }
    if (type && typeof type === "string")
      if (type === "live" && i === 0) {
        return list.length === 1 ? <Detail {...preset} showDivider={_showDivider} isFirst={isFirst} isLast={isLast} key={_key} /> : <Detail {...preset} tag="" showDivider={_showDivider} isFirst={isFirst} key={_key} />
      } else if (type === "date") {
        return Array.isArray(preset) ? preset.map((presetTag, j) => {
          const dateDivider = {showDivider: presetTag?.showDivider ? presetTag?.showDivider : tag?.showDivider };
          return (
            <Detail
              {...presetTag}
              showDivider={dateDivider.showDivider}
              isFirst={i === 0 && j === 0}
              isLast={isLast && j === preset.length - 1}
              key={`${_key}-${j}`}
            />
          )
        }) : null;
      } else {
        return (
            <Detail
              {...preset}
              tag={_tag ? _tag : !Array.isArray(preset) ? preset.tag : ""}
              showDivider={_showDivider}
              isFirst={isFirst}
              isLast={isLast}
              key={_key}
            />
          );
      }
    return (
      <Detail {...tag} showDivider={_showDivider} isFirst={isFirst} isLast={isLast} key={_key} />
    );
  }).filter(tag => !!tag);

  if (!_list?.length) return null;

  return (
    <div
      className={cx(
        {
          "cui-py-1": !noPad,
          "cui-flex-wrap": !noWrap,
          [className]: !!className
        },
        "cui__details cui-flex cui-items-center cui-gap-y-2px cui-min-h-[26px]"
      )?.trim()}
    >
      {_list}
    </div>
  );
};
