import React, { useState, useMemo, useEffect } from "react";
import IconList from "./components/IconList";
import { ButtonGroup, Heading } from "@caesars-digital/caesars-ui";
import { icons as uiIcons } from "tokens/tokens/icons/ui-icons/ui-icons.json";
import { icons as sportsIcons } from "tokens/tokens/icons/sports-icons/sports-icons.json";
import { icons as countryFlags } from "tokens/tokens/icons/country-flags/country-flags.json";
import { icons as brandIcons } from "tokens/tokens/icons/brand-icons/brand-icons.json";
import teams from "tokens/s3assets/json/teams.json";
import fuzzysort from "fuzzysort";

const allIcons = [...uiIcons, ...sportsIcons, ...countryFlags, ...brandIcons];
const allTeams = Object.entries(teams.teams)
  .map(([league, teams]) => Object.values(teams))
  .flat();

const tabMenu = [
  { itemId: 0, label: "UI", search: "icon_ui" },
  { itemId: 1, label: "Brand", search: "brand" },
  { itemId: 2, label: "Flags", search: "flag" },
  { itemId: 3, label: "Team Logos", search: "logo" },
  { itemId: 4, label: "Sports", search: "icon_sports" },
];

const tabMenuLogos = [
  { itemId: 0, label: "MLB", search: "mlb" },
  { itemId: 1, label: "NBA", search: "nba" },
  { itemId: 9, label: "WNBA", search: "wnba" },
  { itemId: 2, label: "NFL", search: "nfl" },
  { itemId: 3, label: "NHL", search: "nhl" },
  { itemId: 4, label: "Soccer", search: "soccer" },
  { itemId: 5, label: "NCAAB", search: "college", sport: "basketball" },
  { itemId: 6, label: "NCAAF", search: "college", sport: "football" },
  { itemId: 7, label: "NCAA Baseball", search: "college", sport: "baseball" },
  { itemId: 8, label: "NCAAH", search: "college", sport: "hockey" },
];

const IconListContainer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTabId, setActiveTabId] = useState(0);
  const [activeLogosTabId, setActiveLogosTabId] = useState(0);

  const logoActive = activeTabId === 3;

  const hiddenSearch = useMemo(() => {
    return tabMenu.filter((tab) => tab.itemId === activeTabId)[0].search || "";
  }, [activeTabId]);

  const activeTeams = useMemo(() => {
    const activeLeague = tabMenuLogos.filter(
      (t) => t.itemId === activeLogosTabId
    )[0].search;
    return Object.values(teams.teams[activeLeague]);
  }, [activeLogosTabId]);

  const activeSport = useMemo(() => {
    return tabMenuLogos.filter(
        (t) => t.itemId === activeLogosTabId
    )[0].sport;
  }, [activeLogosTabId]);

  const hasSearch = useMemo(() => !!searchTerm, [searchTerm]);
 
  const filteredIcons = useMemo(() => {
    const opts = {
      threshold: -200,
      keys: ["tags", "name"],
    };
    if (!logoActive) {
      return fuzzysort.go(
        hasSearch ? searchTerm : hiddenSearch,
        allIcons,
        opts
      );
    } else {
      if (hasSearch) return fuzzysort.go(
        searchTerm,
        allTeams,
        {...opts, keys: ["name"]}
      );
      return fuzzysort.go(
        searchTerm,
        activeTeams,
        {...opts, all: true}
      );
    }
  }, [searchTerm, activeTabId, activeLogosTabId]);

  return (
    <>
      <div>
        <ButtonGroup
          items={tabMenu}
          initialItem={0}
          className="mb-xl"
          handleClick={(e, id) => {
            setActiveTabId(id);
          }}
        />
        {logoActive ? (
          <ButtonGroup
            items={tabMenuLogos}
            initialItem={0}
            className="mb-xl ml-xl"
            handleClick={(e, id) => {
              setActiveLogosTabId(id);
            }}
          />
        ) : null}
      </div>

      <div className="mb-xl flex flex-col gap-sm">
        <Heading size="md" tag="label">
          Filter icons by name or keyword
        </Heading>
        <input
          className="bg-bg-default border-1 border-solid border-1px border-fg-moderate max-w-[300px] rounded-1 px-2 py-1"
          name="filter"
          type="text"
          value={searchTerm}
          placeholder="Enter search term..."
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <IconList
        icons={filteredIcons}
        hasSearchTerm={!!searchTerm}
        useTeamLogo={logoActive}
        activeSport={activeSport || ''}
      />
    </>
  );
};

export default IconListContainer;
