import { ReactNode } from "react";
import { LiveIcon } from "./LiveIcon";
import { BaseballDiamond } from "./BaseballDiamond";
import QuickPickIcon from "./QuickPickIcon";

export type GeneratedIconType = ({fill, secondaryfill, width, height, options}: { fill: string; secondaryfill: string; width: number | string; height: number | string; options?: Record<string, any>}) => ReactNode;

export const customIcons: Record<string, ReactNode> = {
    "iconUiLiveAnimated": LiveIcon,
    "iconUiBaseballDiamond": BaseballDiamond,
    "iconUiQuickPick": QuickPickIcon
}